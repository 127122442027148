<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    :no-content="noContent"
    title="Information"
  >
    <template #toolbar>
      <dsb-contracts-sync
        v-if="hadAdminRole"
        @sync:success="$_refreshData"
      />
    </template>

    <vbt-table
      :headers="headers"
      :items="contracts"
      :page-size="100"
      show-paginator
    >
      <template #orderWaitTime="{ item: { orderWaitTime } }">
        <v-layout v-if="orderWaitTime">
          <strong class="mr-2">
            Days:
          </strong>
          {{ orderWaitTime.days }};

          <strong class="mx-2">
            Hours:
          </strong>
          {{ orderWaitTime.hours }}
        </v-layout>
      </template>

      <template #actions="{ item }">
        <v-layout justify-space-between>
          <v-tooltip
            v-if="hadAdminRole"
            top
          >
            <template #activator="{ on }">
              <v-btn
                color="warning"
                small
                icon
                v-on="on"
                @click="showContractOrderWaitTimeEditor(item.name, item.orderWaitTime)"
              >
                <v-icon>
                  query_builder
                </v-icon>
              </v-btn>
            </template>
            Edit order wait time
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                small
                color="info"
                v-on="on"
                @click="showContractHistory(item.name)"
              >
                <v-icon>
                  book
                </v-icon>
              </v-btn>
            </template>
            Show history
          </v-tooltip>
        </v-layout>
      </template>
    </vbt-table>

    <dsb-contract-history
      v-model="contractHistory.show"
      :name="contractHistory.name"
    />

    <dsb-contract-order-wait-time-editor
      v-model="contractOrderWaitTime.show"
      :name="contractOrderWaitTime.name"
      :time="contractOrderWaitTime.orderWaitTime"
      @update:success="$_refreshData"
    />
  </vbt-content-box>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import { formatDisplayDate } from '@helpers';

import { AuthNamespace } from '@store/types';

import {
  namespace as ContractsNamespace,
  ActionTypes as ContractsActionTypes,
} from '../store.types';

import DsbContractsSync from '../_components/DsbContractsSync.vue';
import DsbContractHistory from '../_components/DsbContractHistory.vue';
import DsbContractOrderWaitTimeEditor from '../_components/DsbContractOrderWaitTimeEditor.vue';

const dataLoadingOptions = {
  getterName: ContractsActionTypes.GET_CONTRACTS,
  contentName: 'contracts',
  searchable: false,
  paged: false,
};

const headers = Object.freeze([
  { text: 'Name', value: 'name' },
  { text: 'Order Wait Time', value: 'orderWaitTime' },
  { text: '', value: 'actions', width: '90px' },
]);

export default {
  name: 'DsbContracts',

  components: {
    DsbContractsSync,
    DsbContractHistory,
    DsbContractOrderWaitTimeEditor,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  data() {
    return {
      contractHistory: { name: '', show: false },
      contractOrderWaitTime: {
        name: '',
        show: false,
        orderWaitTime: { days: 0, hours: 0 },
      },

      headers,
    };
  },

  computed: {
    ...mapState(ContractsNamespace, ['contracts']),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),
  },

  methods: {
    ...mapActions(ContractsNamespace, [ContractsActionTypes.GET_CONTRACTS]),

    showContractHistory(name) {
      this.contractHistory.name = name;
      this.contractHistory.show = true;
    },

    showContractOrderWaitTimeEditor(name, orderWaitTime) {
      this.contractOrderWaitTime.name = name;
      this.contractOrderWaitTime.show = true;
      this.contractOrderWaitTime.orderWaitTime = orderWaitTime;
    },

    formatDisplayDate,
  },
};
</script>
