<template>
  <vbt-dialog
    v-model="dialog"
    :title="title"
    submit-btn-name="Save"
    @submit="updateContractOrderWaitTime"
  >
    <v-form :ref="formRef">
      <v-layout align-center>
        <v-text-field
          v-model.trim.number="days"
          :rules="validator.getRulesByField('days')"
          label="Days"
          class="mr-2"
          @keypress.enter="updateContractOrderWaitTime"
        />

        <v-text-field
          v-model.trim.number="hours"
          :rules="validator.getRulesByField('hours')"
          label="Hours"
          class="ml-2"
          @keypress.enter="updateContractOrderWaitTime"
        />
      </v-layout>
    </v-form>
  </vbt-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { useDialog, useFormWithRef } from '@mixins/factories';

import { Validator, ValidationRules, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { ContractsApiService } from '../_services/contracts.api.service';

const validator = Validator.createValidator({
  days: [ValidationRules.REQUIRED, ValidationRules.NUMBER, ValidationRules.POSITIVE_NUMBER],
  hours: [ValidationRules.REQUIRED, ValidationRules.NUMBER, ValidationRules.POSITIVE_NUMBER],
});

export default {
  name: 'DsbContractOrderWaitTimeEditor',

  props: {
    name: {
      type: String,
      required: true,
    },
    time: {
      type: Object,
      required: true,
    },
  },

  mixins: [
    useFormWithRef(),
    Validator.useValidator(validator),
    useDialog({ openHandlerName: 'initValues' }),
  ],

  data() {
    return {
      days: 0,
      hours: 0,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    title() {
      return `Edit order wait time of: ${this.name}`;
    },
  },

  methods: {
    initValues() {
      this.days = this.time.days;
      this.hours = this.time.hours;
    },

    updateContractOrderWaitTime() {
      if (!this.$_validateForm()) {
        return;
      }

      this.$VBlackerTheme.alert.warning({
        text: 'Update order wait time?',
      }, () => this.wrapToLoadingFn({
        req: ContractsApiService.updateContractOrderWaitTime.bind({}, {
          suffix: this.suffix,
          name: this.name,
          days: this.days,
          hours: this.hours,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully updated');
          this.$emit('update:success');
          this.$_closeDialog();
        },
      }));
    },

    wrapToLoadingFn,
  },
};
</script>
