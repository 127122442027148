<template>
  <vbt-dialog
    v-model="historyDialog"
    :title="title"
    hide-submit-btn
    @close="$_closeDialog"
  >
    <vbt-table
      :headers="headers"
      :items="contractHistory"
      show-paginator
    >
      <template #processed="{ item: { processed: _dateForFormatting } }">
        {{ formatDisplayDate(_dateForFormatting) }}
      </template>

      <template #orderWaitTime="{ item: { orderWaitTime } }">
        <v-layout v-if="orderWaitTime">
          <strong class="mr-2">
            Days:
          </strong>
          {{ orderWaitTime.days }};

          <strong class="mx-2">
            Hours:
          </strong>
          {{ orderWaitTime.hours }}
        </v-layout>
      </template>
    </vbt-table>
  </vbt-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { useDialog } from '@mixins/factories';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import {
  namespace as ContractsNamespace,
  ActionTypes as ContractsActionTypes,
} from '../store.types';

const headers = Object.freeze([
  { text: 'Operator', value: 'operator' },
  { text: 'Processed Date', value: 'processed' },
  { text: 'Order Wait Time', value: 'orderWaitTime' },
]);

export default {
  name: 'DsbContractHistory',

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  mixins: [
    useDialog({ openHandlerName: 'showContractHistory' }),
  ],

  data() {
    return {
      historyDialog: false,

      headers,
    };
  },

  computed: {
    ...mapState(ContractsNamespace, ['contractHistory']),

    title() {
      return `"${this.name}" history`;
    },
  },

  methods: {
    ...mapActions(ContractsNamespace, {
      showContractHistory(dispatch, name = this.name) {
        this.wrapToLoadingFn({
          req: dispatch.bind({}, ContractsActionTypes.GET_CONTRACT_HISTORY, { name }),
          fullscreenLoadingSpinner: true,
          onSuccess: () => {
            if (this.contractHistory.length) {
              this.historyDialog = true;
            } else {
              this.$VBlackerTheme.notification.warning('Empty history');
              this.$_closeDialog();
            }
          },
        });
      },
    }),

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
