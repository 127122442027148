var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-content-box',{attrs:{"loading":_vm.loading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"title":"Information"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [(_vm.hadAdminRole)?_c('dsb-contracts-sync',{on:{"sync:success":_vm.$_refreshData}}):_vm._e()]},proxy:true}])},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.contracts,"page-size":100,"show-paginator":""},scopedSlots:_vm._u([{key:"orderWaitTime",fn:function(ref){
var orderWaitTime = ref.item.orderWaitTime;
return [(orderWaitTime)?_c('v-layout',[_c('strong',{staticClass:"mr-2"},[_vm._v(" Days: ")]),_vm._v(" "+_vm._s(orderWaitTime.days)+"; "),_c('strong',{staticClass:"mx-2"},[_vm._v(" Hours: ")]),_vm._v(" "+_vm._s(orderWaitTime.hours)+" ")]):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-space-between":""}},[(_vm.hadAdminRole)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"warning","small":"","icon":""},on:{"click":function($event){return _vm.showContractOrderWaitTimeEditor(item.name, item.orderWaitTime)}}},on),[_c('v-icon',[_vm._v(" query_builder ")])],1)]}}],null,true)},[_vm._v(" Edit order wait time ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"info"},on:{"click":function($event){return _vm.showContractHistory(item.name)}}},on),[_c('v-icon',[_vm._v(" book ")])],1)]}}],null,true)},[_vm._v(" Show history ")])],1)]}}])}),_c('dsb-contract-history',{attrs:{"name":_vm.contractHistory.name},model:{value:(_vm.contractHistory.show),callback:function ($$v) {_vm.$set(_vm.contractHistory, "show", $$v)},expression:"contractHistory.show"}}),_c('dsb-contract-order-wait-time-editor',{attrs:{"name":_vm.contractOrderWaitTime.name,"time":_vm.contractOrderWaitTime.orderWaitTime},on:{"update:success":_vm.$_refreshData},model:{value:(_vm.contractOrderWaitTime.show),callback:function ($$v) {_vm.$set(_vm.contractOrderWaitTime, "show", $$v)},expression:"contractOrderWaitTime.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }